import React from 'react';
import '../../index.css';

function About() {
  return (
    <div className="bg-zinc-100">
      <div className="w-full h-28 bg-[#434247]"></div>

      <section className="container mx-auto max-w-[1240px] text-zinc-800 bg-zinc-100 px-4 md:px-6 lg:px-8 pb-8">
        <div className="px-5 py-8 mx-auto">
          <h2 className="pb-8 text-2xl md:text-3xl lg:text-4xl font-bold font-questrial uppercase text-zinc-900 mt-5 text-center">Why Choose Us?</h2>
          <div className="flex flex-col space-y-6 text-left text-base md:text-lg lg:text-xl leading-relaxed">
            <p>Hello, I&rsquo;m <span className="font-bold">Sandro Norim</span>, founder of <span className="font-bold">SANERO</span>, providing expert dental equipment repair and maintenance services in Norfolk and Suffolk. With a Master&rsquo;s in Biomedical Engineering, specialising in Medical Instrumentation, and over 10 years of field service experience across diverse industries, I bring extensive expertise and a comprehensive approach to servicing complex dental equipment. Additionally, I&rsquo;ve completed specialised training in Dental Equipment Servicing with Avensys UK to ensure I stay at the forefront of the industry.</p>

            <p>At <span className="font-bold">SANERO</span>, we offer maintenance services for a wide range of dental equipment, including:</p>
            <ul className="list-disc list-inside">
              <li>Compressors</li>
              <li>Suction pumps</li>
              <li>Autoclaves</li>
              <li>Dental chairs</li>
              <li>X-ray equipment</li>
              <li>Handpieces</li>
            </ul>

            <p>As a special offer, the first service comes with no call-out fee. You will only be charged for labour and any necessary parts. If you&rsquo;re interested in learning more about how we can support your clinic, I&rsquo;d be happy to arrange a visit, assess your equipment, and discuss your specific needs.</p>

            <p>I look forward to the opportunity to provide reliable and personalised service for all your dental equipment needs.</p>

            <p className="font-bold">Kind regards,<br/>Sandro Norim</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;