import React from 'react';
import '../../index.css';
import HeroSection from '../HeroSection';
import Services from '../Services';
import Contacts from '../Contacts';

function Home() {
  return (
    <>
      <HeroSection />
      <Services />
      <Contacts/>
    </>
  );
}

export default Home;
