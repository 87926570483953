import React from 'react';
import '../index.css';
import { FaPhone, FaEnvelope } from "react-icons/fa";

function Contacts() {
  return (
    <div id="contacts" className="bg-zinc-200">
      <div className="container max-w-[1920px] mx-auto md:px-6">
        <section className="px-5 py-20">
          <div className="flex flex-wrap">
            <div className="mb-10 w-full shrink-0 grow-0 basis-auto md:mb-0 md:w-6/12">
              <h2 className="pb-4 text-4xl font-bold font-questrial uppercase text-zinc-900 mt-5">Contact us</h2>
              <p className="mb-6 font-medium text-zinc-800 text-xl">
              Based in Norwich, United Kingdom, we provide professional dental equipment services across the East Anglia region. If you face any issues with your dental equipment, please don’t hesitate to contact us today to schedule a service appointment.
              </p>
              <p className="mb-2 font-medium text-zinc-800 flex items-center text-xl">
                <FaPhone aria-label="Phone" /> <span className="ml-2">07500 956 646</span>
              </p>
              <p className="mb-2 font-medium text-zinc-800 flex items-center text-xl">
                <FaEnvelope aria-label="Email" /> <span className="ml-2">info@sanero.pro</span>
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Contacts;
