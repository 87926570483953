import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import '../index.css';

const Navbar = () => {
  const location = useLocation();

  return (
    <div className="w-full bg-transparent absolute top-0 left-0 z-50">
      <div className='container bg-transparent flex flex-wrap justify-between items-center max-w-[1920px] mx-auto px-6 py-6 text-zinc-50'>
        {/* Logo */}
        <RouterLink to="/">
          <img src="./logos/logo_nobg.svg" alt="Sanero Logo" className="object-cover max-h-12 md:max-h-14 lg:max-h-16"/>
        </RouterLink>

        {/* Buttons */}
        <div className='flex space-x-4'>
          {location.pathname === '/about' ? (
            <RouterLink 
              to="/" 
              className='p-4 cursor-pointer duration-300 border border-[#FF4D9A] sm:border-transparent hover:border-[#FF4D9A] rounded-xl'
            >
              Return Home
            </RouterLink>
          ) : (
            <>
              <ScrollLink 
                to="contacts" 
                smooth={true} 
                duration={500} 
                className='p-4 cursor-pointer duration-300 border border-[#FF4D9A] sm:border-transparent hover:border-[#FF4D9A] rounded-xl'
              >
                Contacts
              </ScrollLink>
              <RouterLink 
                to="/about" 
                className='p-4 cursor-pointer duration-300 border border-[#FF4D9A] sm:border-transparent hover:border-[#FF4D9A] rounded-xl'
              >
                About
              </RouterLink>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;